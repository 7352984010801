import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiUrls, engine } from "../../../services/apiService";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

const EventForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data, isPending, isFetched } = useQuery({
    queryFn: () =>
      engine.get(
        `/api/marketing-events?filters[utmCode][$eq]=${searchParams.get(
          "utm_campaign"
        )}&filters[active][$eq]=true&populate=agents`
      ),
    queryKey: [
      "marketing-events",
      `filters[utmCode][$eq]=${searchParams.get(
        "utm_campaign"
      )}&filters[active][$eq]=true`,
    ],
  });

  const agents = useMemo(
    () => data?.data?.data?.[0]?.attributes?.agents?.data,
    [data]
  );

  const {
    error: submissionError,
    isPending: isSubmissionLoading,
    mutate: execute,
  } = useMutation({
    onSuccess: () => {
      toast.success("Pengisian form sukses! Terima kasih!");
    },
    mutationFn: (formValues) =>
      engine.post(apiUrls.eventForms.submitEventForm, { data: formValues }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      instansi: "",
      agent: "",
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (isFetched && !data?.data?.data?.length) {
      navigate("/");
    }
  }, [data, isFetched, navigate]);

  if (isPending) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "500px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <HomeContainer>
      <Stack maxWidth="650px" padding={2} rowGap={4}>
        <div>
          <Typography
            variant={isMobile ? "h2" : "h1"}
            sx={{
              width: "fit-content",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            Form Event
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: isMobile ? "16px" : "24px",
            }}
          >
            Yuk kenalan!
          </Typography>
        </div>
        <Grid spacing={4} maxWidth="650px" container>
          <Grid sx={{ position: "relative" }} item width="100%" xs={12} md={6}>
            <Controller
              name="name"
              disabled={isSubmissionLoading}
              control={control}
              rules={{ required: "Nama tidak boleh kosong" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  sx={{
                    width: "100%",
                    "& label": {
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  }}
                  variant="outlined"
                  error={Boolean(errors?.name?.message)}
                  label="Nama"
                />
              )}
            />
            <Typography
              sx={{ position: "absolute", bottom: -24, left: 32 }}
              color="error.light"
              fontSize={14}
              fontWeight={700}
            >
              {errors?.name?.message}
            </Typography>
          </Grid>
          <Grid sx={{ position: "relative" }} item width="100%" xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              disabled={isSubmissionLoading}
              rules={{
                required: "Email tidak boleh kosong",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Email tidak valid",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  sx={{
                    width: "100%",
                    "& label": {
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  }}
                  variant="outlined"
                  error={Boolean(errors?.email?.message)}
                  label="Email"
                />
              )}
            />
            <Typography
              sx={{ position: "absolute", bottom: -24, left: 32 }}
              color="error.light"
              fontSize={14}
              fontWeight={700}
            >
              {errors?.email?.message}
            </Typography>
          </Grid>
          <Grid sx={{ position: "relative" }} item width="100%" xs={12} md={6}>
            <Controller
              name="phone"
              disabled={isSubmissionLoading}
              control={control}
              rules={{
                required: "No. Telp tidak boleh kosong",
                pattern: {
                  value:
                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  message: "No. Telp tidak valid",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  sx={{
                    width: "100%",
                    "& label": {
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  }}
                  error={Boolean(errors?.phone?.message)}
                  variant="outlined"
                  label="No. Telp"
                />
              )}
            />
            <Typography
              sx={{ position: "absolute", bottom: -24, left: 32 }}
              color="error.light"
              fontSize={14}
              fontWeight={700}
            >
              {errors?.phone?.message}
            </Typography>
          </Grid>
          <Grid sx={{ position: "relative" }} item width="100%" xs={12} md={6}>
            <Controller
              name="instansi"
              control={control}
              disabled={isSubmissionLoading}
              rules={{ required: "Instansi tidak boleh kosong" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  sx={{
                    width: "100%",
                    "& label": {
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  }}
                  variant="outlined"
                  error={Boolean(errors?.instansi?.message)}
                  label="Instansi Asal"
                />
              )}
            />
            <Typography
              sx={{ position: "absolute", bottom: -24, left: 32 }}
              color="error.light"
              fontSize={14}
              fontWeight={700}
            >
              {errors?.instansi?.message}
            </Typography>
          </Grid>
          <Grid sx={{ position: "relative" }} item xs={12}>
            <Controller
              name="agent"
              disabled={isSubmissionLoading}
              control={control}
              rules={{ required: "Agent tidak boleh kosong" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  sx={{
                    width: "100%",
                    "& label": {
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                    "& .MuiTypography-root": {
                      color: "primary.main",
                    },
                  }}
                  select
                  error={Boolean(errors?.agent?.message)}
                  label="Agent"
                  placeholder="Agent"
                >
                  {agents?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.attributes?.agentName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Typography
              sx={{ position: "absolute", bottom: -24, left: 32 }}
              color="error.light"
              fontSize={14}
              fontWeight={700}
            >
              {errors?.agent?.message}
            </Typography>
          </Grid>
        </Grid>

        <Button
          disabled={isSubmissionLoading}
          marginTop={4}
          variant="contained"
          onClick={handleSubmit((formData) => {
            execute({ ...formData, marketingEvent: data?.data?.data?.[0]?.id });
          })}
          size="large"
          disableElevation
          fullWidth
          type="submit"
        >
          Submit
        </Button>
        {submissionError && (
          <Typography
            sx={{
              marginTop: 1,
              display: "flex",
              width: "100%",
              wordBreak: "break-all",
              flexWrap: "wrap",
            }}
            color="error.light"
            fontSize={14}
            fontWeight={700}
          >
            {submissionError?.response?.data?.error?.name === "ValidationError"
              ? "Pastikan semua baris terisi serta email dan nomor teleponmu belum pernah digunakan sebelumnya ya"
              : "Oops, maaf terjadi kesalahan"}
          </Typography>
        )}
      </Stack>
    </HomeContainer>
  );
};

export default EventForm;
