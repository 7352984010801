import axios from "axios";

export const engine = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ENGINE,
});

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

export const apiHpam = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_HPAM_ENGINE,
});

export const apiUrls = {
  eventForms: {
    submitEventForm: "/api/marketing-event-submissions",
  },
  blog: {
    getBlogPosts: "/api/blogs?sort[0]=createdAt%3Adesc&populate=*",
    getBlogBySlug: "/api/blogs?filters[slug][$eq]=${slug}&populate=image",
  },
  produk: {
    getProduk: "/produk",
    getProdukNav: "/produk/nav3?kinerja=1Y&produk_id=${id}",
  },
  aboutUs: {
    getAboutUs:
      "/api/about-uses?locale=id&populate[0]=about_us_components&populate[1]=about_us_components.image&populate[2]=about_us_components.icon&populate[3]=about_us_components.item&populate[4]=about_us_components.item.photo&populate[5]=about_us_components.dropdowns&sort=id:asc&&populate=about_us_components.dropdowns.icon",
  },
  promo: {
    getPromos: "/api/promos?populate=promo_contents",
    getPromoBySlug:
      "/api/promos?filters[promo_slug][$eq]=${slug}&populate[0]=promo_contents&populate[1]=promo_contents.dropdowns_no_image&populate[2]=promo_contents.dropdowns_with_imgs.image&populate[3]=promo_contents.image&populate[4]=promo_image&populate[5]=promo_image_mobile",
  },
  shortlink: {
    getShortlink:
      "/api/shortlinks?filters[shortlink][$eq]=${param}&populate[click_counter]=*",
  },
  download: {
    participant: "/api/webinar-participants",
  },
};
